exports.components = {
  "component---src-i-18-n-redirect-js": () => import("./../../../src/i18n/Redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consumer-behavior-js": () => import("./../../../src/pages/consumer-behavior.js" /* webpackChunkName: "component---src-pages-consumer-behavior-js" */),
  "component---src-pages-data-analytics-js": () => import("./../../../src/pages/data-analytics.js" /* webpackChunkName: "component---src-pages-data-analytics-js" */),
  "component---src-pages-energy-efficiency-js": () => import("./../../../src/pages/energy-efficiency.js" /* webpackChunkName: "component---src-pages-energy-efficiency-js" */),
  "component---src-pages-grid-js": () => import("./../../../src/pages/grid.js" /* webpackChunkName: "component---src-pages-grid-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-market-research-js": () => import("./../../../src/pages/market-research.js" /* webpackChunkName: "component---src-pages-market-research-js" */),
  "component---src-pages-marketing-perlas-js": () => import("./../../../src/pages/marketing-perlas.js" /* webpackChunkName: "component---src-pages-marketing-perlas-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-opt-out-js": () => import("./../../../src/pages/opt-out.js" /* webpackChunkName: "component---src-pages-opt-out-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-smart-energy-js": () => import("./../../../src/pages/smart-energy.js" /* webpackChunkName: "component---src-pages-smart-energy-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

